import React, { useEffect, useLayoutEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import { useImmer } from "use-immer";
import Modali, { useModali } from 'modali';

import useDataHierarchy from "hooks/use-hierarchy";
import useUser from 'hooks/use-user';
import { usePortalAppContext } from 'context/portal-app-context';
import usePermissions from 'hooks/use-permissions';
import useLocale from 'hooks/use-locale';

import Pill from 'components/pill';
import PenImage from 'assets/icons/pen.svg';
import PenDisabledImage from 'assets/icons/pen-disabled.svg';
import EyeDisabledImage from 'assets/icons/eye-disabled.svg';
import EyeImage from 'assets/icons/eye_2.svg';
import TrashImage from 'assets/icons/trash.svg';
import TrashDisabledImage from 'assets/icons/trash-disabled.svg';
import CloseImage from 'assets/icons/close.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';


import PanelDropdown from 'components/PanelDropdown'
import HierarchyTierSelector from './HierarchyTierSelector';
import SearchByMid from 'components/DataAccessEditor/SearchByMid/SearchByMid';
import ViewByMid from 'components/DataAccessEditor/SearchByMid/ViewByMid';
import MidSelector from 'components/DataAccessEditor/MidSelector/MidSelector';
import SearchBySelector from 'components/DataAccessEditor/SearchBySelector';
import AddBySelector from 'components/DataAccessEditor/AddBySelector';
import SearchByGroup from 'components/DataAccessEditor/SearchByGroup/SearchByGroup';
import ViewByGroup from 'components/DataAccessEditor/SearchByGroup/ViewByGroup';
import LocalizedText from 'components/Translations/LocalizedText';

const HierarchySelector = ({
  rowKey,
  dropdownPortal,
  selectedHierarchyRow,
  preSelectedPortfolio,
  showViewMids = false,
  canEdit = true,
  allowFullPortfolioAccess,
  lockRowFromEditing,
  onSetSearchBy,
  onHierarchyEditModeChanged,
  onHierarchyPortfolioSelected,
  onHierarchyUpdated,
  onHierarchyDeleted,
  onUpdateDirectMids,
  onDiscardRowChanges,
  lazyLoadItems = false,
  setSavedGroup,
  onSelectGroup,
  onDAGDelete,
  allSelectedGroups,
  isCreateMode,
  myself = true,
  someoneElseEmail,
  portfolios = [],
  enabledSelectMethods= [],
}) => {
  const { enqueueAlert } = usePortalAppContext();
  const [group, setGroup] = useState(selectedHierarchyRow?.selectedGroup ? selectedHierarchyRow?.selectedGroup : []);
  const [midModal, toggleMidModal] = useModali({
    overlayClose: false,
    closeButton: false,
    large: true,
    centered: true,
  });
  const { getNode, getChildrenOfNode} = useDataHierarchy();
  const { getAuthenticatedSessionUser, getUserByEmailDataAccess, hasDirectHierarchyAccess } = useUser();
  const [rootHierarchyNode, setRootHierarchyNode] = useState();
  const [profileIsLoading, setProfileIsLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [currentLineage, setCurrentLineage] = useState();
  const [allMidsSelected, setAllMidsSelected] = useState(false);
  const [availableMids, setAvailableMids] = useState([]);
  const [totalMids, setTotalMids] = useState(0);
  const [editMode, setEditModeState] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);
  const [midsViewOnly, setMidsViewOnly] = useState(false);
  const [loadingTierIndex, setLoadingTierIndex, loadingTierIndexRef] = useStateRef(-1);
  const [singleTierMerchantPortfolioSelected, setSingleTierMerchantPortfolioSelected, singleTierMerchantPortfolioSelectedRef] = useStateRef(false);
  const [searchBy, setSearchBy] = useState(selectedHierarchyRow.searchBy);
  const [directMidsSelected, setDirectMidsSelected] = useState([]);
  const[editing,setEditing]=useState(true);
  const isEditing = () =>selectedHierarchyRow.searchBy?.value === 'DataAccessGroup'? (  selectedHierarchyRow?.selectedGroup ? editing && editMode : editing ) : editMode || (selectedHierarchyRow.selectedHierarchy === null && selectedHierarchyRow.selectedMids.length === 0 );
   const [hasDirectAccessToSelectedHierarchy, setHasDirectAccessToSelectedHierarchy] = useState(false);
   const [showSelectedGroupFlag, setSelectedGroupFlag] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState([]);
  const [initialTiersOnEdit, setInitialTiersOnEdit] = useState([]);
  const { hasPermission } = usePermissions();
  const { translateToString } = useLocale();
  const selectedPortfolioData = loggedInUser?.portfolios?.find(p => p?.affiliation === selectedHierarchyRow.selectedPortfolio);
  const selectedPortfolioMaxTiers = selectedPortfolioData?.tiers?.length;
  const singleTierMerchant = selectedPortfolioData?.tiers.length === 0;

  const isTransient = selectedHierarchyRow.selectedMidRows?.some((r) => r.userHierarchyAssignmentId === "TRANSIENT");
  const hierarchySelectionEnabled = enabledSelectMethods.find(m => m === 'Hierarchy');
  const canAssignDataAccessHierarchy = (selectedHierarchyRow.selectedMids?.length === 0 || isTransient) && !hierarchySelectionEnabled;

  const isFullPortfolioSelected = () => {
    return selectedHierarchyRow.nodes && selectedHierarchyRow.nodes[0]?.selectedChild?.label === 'All';
  };

  const isPortfolioSelected = () => {
    return !!selectedHierarchyRow.selectedPortfolio;
  };

  const onCloseRowEditor = () => {
    onHierarchyEditModeChanged(rowKey, false);
    onDiscardRowChanges(rowKey);
    setEditModeState(false);
    setSelectedMids(selectedHierarchyRow ? selectedHierarchyRow.selectedMids : []);
    setSelectedMidRows(selectedHierarchyRow ? selectedHierarchyRow.selectedMidRows : []);
    setTotalMids(0);
    updateTiers(initialTiersOnEdit);
  };
  
  const setEditMode = (mode) => {
    setEditModeState(mode);
    onHierarchyEditModeChanged(rowKey, mode);
  };

  const tierKeyMap = {
    "BANK": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.bank',
    "GROUP": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.group',
    "ASSOC": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.assoc',
    "CORP": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.corp',
    "REGIO": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.reg',
    "PRINC": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.prin',
    "CHAIN": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.chain',
    "TAX_I": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.taxid',
    "DOMAI": 'hierarchySelector.selectDataAccess.hierarchy.tiers.tierNames.domain'
  }

  // todo: hotfix made to address regression issue with accounts/portal-common-ui integration.  This hotfix breaks support for multiple portfolios (which is not yet in actual use).
  // When we add multiple portfolios for accounts we need to pull defaultTiers from the portfolio object instead of hard-coding
  const dummyTiers = ['BANK','GROUP','ASSOC'].map(dummyTier => { 
    return { 
      tierName: translateToString(tierKeyMap[dummyTier]), 
      classes: 'mb-[4px] ml-[8px] w-[72px]', 
      isFocused: false 
    }
  });

  const defaultTiers = (isPortfolioSelected() && selectedPortfolioData) ? selectedPortfolioData?.tiers.map(tier => {
    return {
      tierName: translateToString(tierKeyMap[tier?.tierName?.substring(0,5)]),
      classes: 'mb-[4px] ml-[8px] w-[72px]',
      isFocused: false,
    }
  }) : dummyTiers;

  const [tiers, updateTiers] = useImmer(selectedHierarchyRow && selectedHierarchyRow.nodes && selectedHierarchyRow.nodes.length > 0 ? selectedHierarchyRow.nodes : defaultTiers);

  const [selectedMids, setSelectedMids] = useImmer(selectedHierarchyRow ? selectedHierarchyRow.selectedMids : []);
  const [selectedMidRows, setSelectedMidRows] = useImmer(selectedHierarchyRow ? selectedHierarchyRow.selectedMidRows : []);
  
  const delay = (timeout) => {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  };

  const lazyLoadListItems = async (tierIndex, searchInput) => {
    const node = await getNode(selectedPortfolioData.dataAccess, tierIndex > 0 ? tiers[tierIndex-1].selectedChild.hierarchyId : null, tierIndex-1, searchInput, someoneElseEmail, selectedPortfolioMaxTiers);
    return node.listEntries;
  };

  const showMidActions = () => {
    const lastTierIdx = tiers.length - 1; // 2; // todo: read this from portfolio hierarchyConfig  // tiers.length - 1;
    const lastTier = tiers.length > lastTierIdx ? tiers[lastTierIdx] : null;
    return lastTier && lastTier.selectedChild && lastTier.selectedChild.label !== 'All';
  };

  const showAddAllMids = () => {
    const lastTierIdx = tiers.length - 1;
    const lastTier = tiers[lastTierIdx];
    return lastTier && lastTier.selectedChild; // && lastTier.hasDirectAccess;
  };

  useEffect(() => {  
    async function loadSessionUserAccess(fetchRootNode) {
      try {
        setProfileIsLoading(true);
        const loggedInUser = !myself ? await getUserByEmailDataAccess(someoneElseEmail) : await getAuthenticatedSessionUser();
        setLoggedInUser(loggedInUser);
        if (fetchRootNode) {
          const selectedPortfolioData = loggedInUser?.portfolios?.find(p => p?.affiliation === selectedHierarchyRow.selectedPortfolio);
          const node = await getNode(selectedPortfolioData.dataAccess, undefined, 0, null, someoneElseEmail, selectedPortfolioMaxTiers);
          const newTiers = [...selectedPortfolioData.tiers.slice()];
          newTiers[0] = { ...newTiers[0] };
          newTiers[0].children = node.listEntries;
          newTiers[0].isFocused = true;
          updateTiers(newTiers);
          setProfileIsLoading(false);
        } else {
          const nodes = selectedHierarchyRow?.nodes;
          const merchantParentNodeSelectedId = nodes?.length > 0 ? nodes[nodes.length-1]?.selectedChild?.hierarchyId : null;
          if (merchantParentNodeSelectedId) {
            const merchantParentNode = await getNode(loggedInUser.dataAccess, merchantParentNodeSelectedId, nodes.length-1, null, someoneElseEmail);
            setAvailableMids(merchantParentNode.listEntries);
          }    
          setProfileIsLoading(false);
        }
      } catch (err) {
        console.log(err);
      } 
    };
    if (isPortfolioSelected()) loadSessionUserAccess(!selectedHierarchyRow || !selectedHierarchyRow.nodes);
   }, [selectedHierarchyRow?.selectedPortfolio]);

  useEffect(() => {
    // if we're in create flow, and they no longer have full portfolio access but it's selected, delete it
    if (!allowFullPortfolioAccess && isFullPortfolioSelected() && !showViewMids) {
      onHierarchyDeleted(rowKey);
    }
  }, [allowFullPortfolioAccess]);

  useEffect(() => {
    // below if statement automatically sets full portfolio if a portfolio associated with a single tier merchant is selected
    if (loggedInUser && (tiers && tiers.length <= 1) && singleTierMerchant && !singleTierMerchantPortfolioSelectedRef.current && isPortfolioSelected() && isEditing()) {
      onHierarchySelected(tiers[0]?.children[0], 0, tiers[0]);
      setSingleTierMerchantPortfolioSelected(true);
    };
    const hasAccess = loggedInUser && showMidActions() ? hasDirectHierarchyAccess(loggedInUser, tiers.map(t => t.selectedChild?.hierarchyId).filter(x => x)) : false;
    setHasDirectAccessToSelectedHierarchy(hasAccess);
  }, [loggedInUser, tiers]);

  useLayoutEffect(() => {
    const element = document.getElementById(`editor-${rowKey}`);
    if (element) {
      setTimeout(() =>element.scrollIntoView(), 100);
    }
  }, [rowKey]);

  const getSelectedHierarchy = () => {
    const tiersCopy = [...tiers];
    const lowestSelectedTier = tiersCopy.reverse().find(r => r && r.selectedChild !== null);
    return lowestSelectedTier.selectedChild;
  };

  const getSelectedLineage = (hierarchyId) => {
    let nodeFound = false;
    const selectedLineage = tiers.reduce((lineage, node) => {
      if (!nodeFound) {
        const prefix = lineage ? `${lineage}|` : '';
        let label = node.selectedChild.label;
        if (label === '000000') label = '';
        const result = `${prefix}${label}`;
        if (node.selectedChild.hierarchyId === hierarchyId) {
          nodeFound = true;
        }
        return result;
      } else {
        return lineage;
      }
    }, null);
    return selectedLineage;
  };


  const onSelectDAG = (group) => {
    setSelectedGroup({
      name: group.name,
      value: group.value,
      label: group.label,
      id: group.id

    });
    selectedGroup.push(group);
    setSavingChanges(true);

  }

  useEffect(() => {
    if (savingChanges) {
      setEditMode(false);
      if (selectedHierarchyRow?.searchBy?.value === 'Hierarchy') {
        const updatedSelectedHierarchy = getSelectedHierarchy();
        onHierarchyUpdated({
          rowKey: selectedHierarchyRow.rowKey,
          selectedHierarchy: updatedSelectedHierarchy,
          selectedMids: selectedMidRows?.map(m => m.hierarchyId) || [],
          selectedMidRows,
          selectedMerchantNumbers: selectedMidRows?.map(m => m.merchantId.trim()) || [],
          selectedLineage: getSelectedLineage(updatedSelectedHierarchy.hierarchyId),
          totalAvailableMids: totalMids,
          nodes: tiers,
          selectedPortfolio: selectedHierarchyRow.selectedPortfolio,
        });
      } else if (selectedHierarchyRow?.searchBy?.value === 'MID') {
        onUpdateDirectMids(selectedHierarchyRow, directMidsSelected);
      } else if (selectedHierarchyRow?.searchBy?.value === 'DataAccessGroup') {
        onSelectGroup({
          rowKey: selectedHierarchyRow.rowKey,
          selectedGroup: selectedGroup,

        });
      }
      setSavingChanges(false);
    }
  }, [savingChanges]);

  const onSearchBySelected = (updatedSearchBy, selectedPortfolio) => {
    setSearchBy(updatedSearchBy);
    onSetSearchBy(selectedHierarchyRow.rowKey, updatedSearchBy, selectedPortfolio);
  };

  const onViewMids = () => {
    setMidsViewOnly(true);
    toggleMidModal();
  };

  const onAddAllMids = async () => {
    setAllMidsSelected(true);
    setEditMode(false);
    setSavingChanges(true);
  };

  const onPartialMidsSelected = async(midRows, midsTotal) => {
    const mids = midRows.map(m => m.hierarchyId);
    toggleMidModal();
    setSelectedMidRows(midRows);
    setSelectedMids(mids);
    setTotalMids(midsTotal);
    if (mids && mids.length > 0) {
      setEditMode(false);
      setSavingChanges(true);
    } else {
      if (!hierarchySelectionEnabled) {
        onDelete(true);
      } else {
        setEditMode(true);
      }
    }
  };

  const onChooseMidsFromList = () => {
    setMidsViewOnly(false);
    toggleMidModal();
  };

  const onEdit = () => {
    if (selectedMids && selectedMids.length > 0) {
      onChooseMidsFromList();
      setInitialTiersOnEdit(tiers);
    } else {
      setEditMode(true);
      onHierarchyEditModeChanged(rowKey, true);
      setInitialTiersOnEdit(tiers);  
    }
  };

  const onDelete = (noMidsNoAssign = false) => {
    enqueueAlert({ 
      alertType: 'modal', 
      title: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.title" />,
      messageType: 'warning', 
      continueCaption: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.continueCaption" />,
      cancelCaption: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.cancelCaption" />,
      continueAction: () => {
        onHierarchyDeleted(selectedHierarchyRow.rowKey);
        if (noMidsNoAssign) setEditMode(false);
      },
      cancelAction: () => {
        if (noMidsNoAssign) onCloseRowEditor();
      },
      message: <LocalizedText localeKey="hierarchySelector.selectDataAccess.alerts.deleteWarning.message" />,
      message2: null,
      modalDialog: true,
    });    
  };

  const searchItemList = async (hierarchyId, searchInput, index) => {
    const node = await getNode(loggedInUser.dataAccess, hierarchyId, index, searchInput, someoneElseEmail);
    return node.listEntries;
  };

  const onSearchMidSelection = async(mids) => {
    setDirectMidsSelected(mids);
    setSavingChanges(true);
  };

  const onHierarchySelected = async (tier, index, tierNode) => {
    try {
      setLoadingTierIndex(index);
      updateTiers(draftTiers => {
        draftTiers[index].isLoading = true;
        draftTiers[index].selectedChild = tier;
        // todo: see todo note above about defaultTiers
        if (index + 1 < defaultTiers.length) {
          const nextTier = index + 1;
          let nextTierNode = draftTiers[nextTier];
          if (!nextTierNode) {
            draftTiers.push(tiers[nextTier] || defaultTiers[nextTier]);
            nextTierNode = draftTiers[nextTier];
          }
        }
        if (index + 1 === draftTiers.length) {
          setAvailableMids([]);
          setTotalMids(0);
        }
        for(let i = index + 2; i < draftTiers.length; i++) {
          draftTiers[i].children = null;
          draftTiers[i].selectedChild = null;
          draftTiers[i].isFocused = false;
          draftTiers[i].disabled = true;
        }
      });

      const selectedTierNode = await getNode(loggedInUser.dataAccess, tier.hierarchyId, index, null, someoneElseEmail);
      updateTiers( draftTiers => {
        draftTiers[index].isFocused = false;
        draftTiers[index].selectedChild = tier;
        if (index + 1 < defaultTiers.length) {
          const nextTier = index + 1;
          let nextTierNode = draftTiers[nextTier] || defaultTiers[nextTier];
          nextTierNode.children = selectedTierNode.listEntries;
          nextTierNode.requiresServerSearch = (selectedTierNode.listEntries?.length || 0) >= 1000;
          nextTierNode.parentHierarchyId = tier.hierarchyId;
          nextTierNode.selectedChild = null;
          nextTierNode.disabled = false;
          nextTierNode.isFocused = true;
        } else if (index + 1 === draftTiers.length) {
            const children = selectedTierNode.listEntries;
            const mids = children
              .filter(c => c.label !== 'All')
              .map(c => {      
                if(editMode){
                  const lineage = c.lineageDetails?.map(l => l.value === 'top_level' ? null : l.value === null ? '' : l.value);    
                  return {
                    hierarchyId: c.hierarchyId,
                    merchantId: c.merchantDemographics ? c.merchantDemographics.merchantNumber : c.merchantId,
                    merchantName: c.merchantDemographics ? c.merchantDemographics.dbaName : c.merchantName,
                    address: c.merchantDemographics ? `${c.merchantDemographics.dbaAddress1}, ${c.merchantDemographics.dbaCity}, ${c.merchantDemographics.dbaPostalCode}` : c.address,
                    hierarchy: `${lineage?.filter(l => l !== null).join(' ') || c.hierarchy || ''}`
                  };
                }else{
                  return {
                    hierarchyId: c.hierarchyId,
                    merchantId: c.merchantDemographics ? c.merchantDemographics.merchantNumber : c.merchantId,
                    merchantName: c.merchantDemographics ? c.merchantDemographics.dbaName : c.merchantName,
                    address: c.merchantDemographics ? `${c.merchantDemographics.dbaAddress1}, ${c.merchantDemographics.dbaCity}, ${c.merchantDemographics.dbaPostalCode}` : c.address,
                  };
                }
              });
            setAvailableMids(mids);
            setTotalMids(selectedTierNode.listEntriesTotalCount);
        }
        if (tier.label === 'All') {
          draftTiers[index].selectedChild = tier;
          for (let i = index + 1; i < draftTiers.length; i++) {
            draftTiers[i].disabled = true;
          }
        }
        draftTiers[index].isLoading = false;
        setLoadingTierIndex(-1);
      });
      if (tier.label === 'All') {
        setAllMidsSelected(true);
        setSavingChanges(true);
      };
    } catch (err) {
      console.log(err);
    }
  }
  const renderTierSelectors = () => {
    return (
      <>
        {
        tiers.map((tier, index) => {  
          if (index === 0 ||
              index === loadingTierIndexRef.current ||
              (tiers[index-1].selectedChild !== null && tiers[index-1]?.selectedChild?.value !== 'All' && loadingTierIndexRef.current + 1 !== index)) { 
                return (
                <HierarchyTierSelector
                  key={tier.tierName}
                  tier={tier}
                  allowAll={index > 0 || allowFullPortfolioAccess}
                  isLoading={tier.isLoading}
                  isDisabled={loadingTierIndexRef.current > -1 || tier.disabled}
                  isEditing={isEditing()}
                  isCreateMode={isCreateMode}  // making this an explicit property instead of assuming !showViewMids means we are in create mode
                  requiresServerSearch={tier.requiresServerSearch}
                  searchList={searchItemList}
                  tierName={<LocalizedText localeKey={tierKeyMap[tier?.tierName?.substring(0,5)]} />}
                  tierIndex={index}
                  selectClassName={tier.classes}
                  currentLineage={currentLineage} 
                  onHierarchySelected={onHierarchySelected}
                  dropdownPortal="tier-dropdown-portal" 
                  isFocused={tier.isFocused}
                  lazyLoadListItems={lazyLoadListItems}
                  editMode={editMode}
                  lockRowFromEditing={lockRowFromEditing}
                />
            );
          } else if (index === loadingTierIndexRef.current + 1) {
            return (
              <div key={tier.tierName} className="flex items-center">
                <div key={tier.tierName} className="flex text-xs text-theme-medium font-bold pl-[3px] pt-[2px] w-[72px]">
                  <div className="pr-[4px]"><LocalizedText localeKey={tierKeyMap[tier?.tierName?.substring(0,5)]} /></div>
                  <div className="font-bold" >
                    <FontAwesomeIcon icon={faSpinner} size="md" spin className="text-sm" />
                  </div>
                </div>
              </div> 
            );
          } else {
            return (
              <React.Fragment>
                {isEditing() &&
                <div key={tier.tierName} className="h-[40px] m-0 pt-[6px] flex items-center">
                  <div key={tier.tierName} className="text-xs placeholder-gray-200 text-theme-disabled w-[72px]"><LocalizedText localeKey={tierKeyMap[tier?.tierName?.substring(0,5)]} /></div>
                </div>
                }
              </React.Fragment>
            );
          }
          })
        }
      </>
    );
  };

  const panelDisplayClass = showMidActions() ? '' : 'invisible';
  // const marginBottomForRow = selectedHierarchyRow.selectedHierarchy === null ? 'mb-72' : '';
  const marginBottomForRow = '';
  let midsPillSkin = 'theme-info';
  let midsPillLabel = <LocalizedText localeKey="hierarchySelector.selectDataAccess.hierarchy.fullPortfolio" />
  if (!isFullPortfolioSelected()) {
    if (selectedHierarchyRow.selectedMids === undefined || selectedHierarchyRow.selectedMids.length === 0) {
      midsPillLabel = <LocalizedText localeKey="hierarchySelector.selectDataAccess.hierarchy.allMIDs" />;
      midsPillSkin = lockRowFromEditing ? 'theme-disabled' : 'theme-info';
    } else {
      midsPillLabel = <LocalizedText localeKey="midSelector.midsTotal" templateVariables={{ totalMidsSelected: selectedHierarchyRow.selectedMids.length }} />
      midsPillSkin = lockRowFromEditing ? 'theme-disabled' : 'theme-success';
    }
  }

  const iconColor  = lockRowFromEditing ? 'theme-medium' : '';
  const eyeIconColor = lockRowFromEditing ? 'theme-disabled' : 'theme-primary';


  const midSelectorTitle = 
    midsViewOnly ? <LocalizedText localeKey="midSelector.title.view" /> :
    selectedHierarchyRow.selectedMids.length === 0 ? <LocalizedText localeKey="midSelector.title.primary" /> : <LocalizedText localeKey="midSelector.title.edit" />;

  let selectedHierarchyId = (selectedHierarchyRow && selectedHierarchyRow.selectedHierarchy) ? selectedHierarchyRow.selectedHierarchy.hierarchyId : null;
  if (isEditing) {
    let selectedTier = getSelectedHierarchy();
    if (selectedTier) {
      selectedHierarchyId = selectedTier.hierarchyId;
    }
  }
  // todo:  for now hide the view mids function for hierarchies selected at the bank or group level until ES based mid search list API is available
  const viewMidsVisibleClass = showViewMids ? 'visible' : 'invisible';

  const showHierarchyEditor = !selectedHierarchyRow.isNewRow || (selectedHierarchyRow.searchBy?.value === 'Hierarchy' && isPortfolioSelected() && tiers && tiers[0] && tiers[0].children);
  const showSelectByMid = selectedHierarchyRow.searchBy && selectedHierarchyRow.searchBy?.value === 'MID';
  const showCrossIcon = lockRowFromEditing || selectedHierarchyRow?.searchBy !== null;
  const showSelectByGroup = selectedHierarchyRow.searchBy && selectedHierarchyRow.searchBy?.value === 'DataAccessGroup';
  const disableEdit = lockRowFromEditing || canAssignDataAccessHierarchy || singleTierMerchant;

  return (  
      <>
      {isEditing() && selectedHierarchyRow.isNewRow && (!selectedHierarchyRow.searchBy?.value || profileIsLoading) && 
        <div className="flex min-h-[40px]">
          <div className="flex items-center min-w-[635px] xl:min-w-[761px] ">
            {!profileIsLoading && 
              <AddBySelector 
                onSearchBySelected={onSearchBySelected} 
                portfolios={portfolios}
                enabledSelectMethods={enabledSelectMethods}
              />
            }
            {profileIsLoading && <div className="text-sm font-normal text-theme-medium px-3"><LocalizedText localeKey="common.loading" /> ...</div>}
          </div>
        </div>
      }
      
    {isEditing() && selectedHierarchyRow.searchBy?.value && !profileIsLoading &&
      <div id={`editor-${rowKey}`} className={`p-0 ${marginBottomForRow} bg-theme-on-primary`}>
        <div className="p-[12px] xl:p-[16px] mt-0 border border-solid border-theme-light-border bg-theme-light-background min-h-[130px] min-w-[635px] xl:min-w-[761px]">
          <div className="mb-0 flex">
            <div className="text-theme-dark font-medium text-sm mb-[8px]">
              {selectedHierarchyRow?.searchBy?.value === 'Hierarchy' && <LocalizedText localeKey="hierarchySelector.selectDataAccess.hierarchy.title" />}
              {selectedHierarchyRow?.searchBy?.value === 'MID' && <LocalizedText localeKey="hierarchySelector.selectDataAccess.mid.title" />}
              {selectedHierarchyRow?.searchBy?.value === 'DataAccessGroup' && <LocalizedText  localeKey="hierarchySelector.selectDataAccess.dataAccessGroup.title" />}
            </div>
            {showCrossIcon &&
            <div className="flex flex-grow justify-end" data-test="dataaccess-close-row-editor">
              <button onClick={onCloseRowEditor}>
                <CloseImage className="cursor-pointer" aria-labelledby={translateToString('common.icons.close')} />
              </button>
            </div>
            }
          </div>
          <div className="flex gap-3 xl:gap-4">
          {selectedHierarchyRow.isNewRow && !selectedHierarchyRow.searchBy?.value && 
            <div className="flex min-h-[40px]">
              <div className="flex items-center min-w-[400px] xl:min-w-[500px] ">
                {!profileIsLoading && 
                  <AddBySelector 
                    onSearchBySelected={onSearchBySelected} 
                    portfolios={portfolios}
                    enabledSelectMethods={enabledSelectMethods}
                  />
                }
                {profileIsLoading && <div className="text-sm font-normal text-theme-medium px-3">Loading...</div>}
                {profileIsLoading && 
                  <div className="flex h-[40px]">
                    {/* <div className="h-full text-xs font-medium  text-theme-info mt-3 px-2"><LoadingDots /></div> */}
                  </div>
                }
              </div>
            </div>
            }
            {showHierarchyEditor && 
            <div className="flex min-h-[40px]">
              <div className="flex bg-theme-on-primary border border-solid border-theme-primary items-center justify-start">
                <div className="flex min-w-[400px] ">
                  {portfolios.length > 1 && <div className="whitespace-nowrap ml-2 my-auto text-theme-info font-medium text-sm">{selectedPortfolioData?.displayName}</div>}
                  {!singleTierMerchantPortfolioSelected.current && renderTierSelectors()}
                </div>
              </div>
            </div>
            }
            {showSelectByMid && 
            <div className="flex min-h-[40px]">
              {/* border border-theme-primary */}
              <div className="flex items-start justify-start">
                <SearchByMid 
                  onMidSelection={onSearchMidSelection} 
                  targetUserEmail={someoneElseEmail}
                  portfolio={preSelectedPortfolio ? portfolios[0].name : undefined}
                />
              </div>
            </div>
            }
             {showSelectByGroup && 
                <div>
                  <SearchByGroup
                    setEditing={setEditing}
                    setEditMode={setEditMode}
                    setSavedGroup={setSavedGroup}
                    onSelectGroup={onSelectDAG}
                    selectedGroups={allSelectedGroups}
                  />
                </div>
              }            
            <div>
              {showHierarchyEditor && 
              <PanelDropdown titleKey="hierarchySelector.panelDropdown.selectActions" utilityClass="px-[16px] text-theme-primary" arrowClass={` text-base text-theme-primary`} containerClass={`w-52 bg-theme-on-primary ${panelDisplayClass}`} buttonClass="h-[40px] w-[40px] border-0 bg-theme-on-primary">
                <button name="chooseMids" onClick={onChooseMidsFromList} className="z-50 bg-theme-on-primary btn my-0 text-theme-primary font-medium hover:bg-theme-info hover:bg-opacity-20 disabled:text-gray-400 text-left pl-[16px] mx-0 normal-case" data-test="dataaccess-choose-MIDs">
                  <LocalizedText localeKey="hierarchySelector.panelDropdown.chooseMIDs" />
                </button>
                {showAddAllMids() && hasDirectAccessToSelectedHierarchy && 
                <button name="addMids" onClick={onAddAllMids} className="z-50 bg-theme-on-primary btn my-0 text-theme-primary font-medium hover:bg-theme-info hover:bg-opacity-20 rounded-none text-left pl-[16px] mx-0 normal-case" style={{ borderTop: '1px solid #d7dce1'}} data-test="dataaccess-add-all-MIDs">
                  <LocalizedText localeKey="hierarchySelector.panelDropdown.addAllMIDs" />
                </button>
                }
              </PanelDropdown>
              }
            </div>
          </div>
        </div>
      </div>
    }
    {!isEditing() && selectedHierarchyRow?.searchBy?.value === 'Hierarchy' &&
      <div className="bg-theme-on-primary border border-solid border-theme-light-border pl-[8px] min-w-[635px] xl:min-w-[761px]" data-test="dataaccess-selected-MID">
        <div className={`${lockRowFromEditing ? 'disabled': ''} flex items-center justify-start `}>
          {portfolios.length > 1 && <div className={`whitespace-nowrap mx-2 my-auto ${lockRowFromEditing ? 'text-theme-disabled' : 'text-theme-info'} font-medium text-base`}>{selectedPortfolioData?.displayName}</div>}
          {!isFullPortfolioSelected() && <div className="flex min-w-[288px] xl:min-w-[384px] justify-start" data-test="dataaccess-tier-selections">{renderTierSelectors()}</div>}
          <div className="pl-6 flex justify-start items-center h-[40px]">
            <Pill themeColor={midsPillSkin} labelText={midsPillLabel} />
          </div>
          <div className="flex w-full justify-end">
            {showViewMids && !isFullPortfolioSelected() && 
              <div className={`flex w-[32px] ${viewMidsVisibleClass}`} data-test="dataaccess-view-button">
                <button type="button" className="bg-theme-on-primary border-0 p-[4px]" disabled={lockRowFromEditing} onClick={onViewMids}>
                  {!lockRowFromEditing && <EyeImage fill={eyeIconColor} />}
                  {lockRowFromEditing && <EyeDisabledImage />}
                </button>
              </div>
            }
           {canEdit &&
            <>
              <div className="flex w-[32px]" data-test="dataaccess-edit-button">
                <button type="button" className="bg-theme-on-primary border-0 p-[4px]" disabled={disableEdit} onClick={onEdit}>
                  {!disableEdit && <PenImage/>}
                  {disableEdit && <PenDisabledImage/>}
                </button>
              </div>
              <div className="flex w-[32px]" data-test="dataaccess-delete-button" >
                <button type="button" className="bg-theme-on-primary border-0 ml-1" onClick={() => onDelete()} disabled={lockRowFromEditing || canAssignDataAccessHierarchy}>
                  {!lockRowFromEditing && !canAssignDataAccessHierarchy && <TrashImage/>}
                  {(lockRowFromEditing || canAssignDataAccessHierarchy) && <TrashDisabledImage/>}
                </button>
              </div>
            </>
            }
          </div>
        </div>
      </div>
    }
    {!isEditing() && selectedHierarchyRow?.searchBy?.value === 'MID' &&
      <div>
        {/* <div className="bg-white border border-theme-light-border p-2"> */}
        <div className={`${lockRowFromEditing ? 'disabled': ''} flex items-center justify-start flex-col gap-y-3 min-w-[635px] xl:min-w-[761px]`}>
          <ViewByMid
            selectedMids={selectedHierarchyRow.selectedMids}
            rowKey={selectedHierarchyRow.rowKey}
            onUpdateDirectMids={(mids) => onUpdateDirectMids(selectedHierarchyRow, mids)}
            onDeleteRow={onHierarchyDeleted}
            iconColor = {iconColor}
            lockRowFromEditing={lockRowFromEditing}
            multiPortfolio={portfolios.length > 1}
            canEdit={canEdit}
          />
        </div>
      </div>
    }
     {(!isEditing() && selectedHierarchyRow?.searchBy?.value === 'DataAccessGroup') &&
        <ViewByGroup
             lockRowFromEditing={lockRowFromEditing}
             showDelete={canEdit}
             selectedHierarchyRow={selectedHierarchyRow}
             onDAGDelete={onDAGDelete}
          />
     }      

    <Modali.Modal {...midModal}>
      <div className="flex items-center justify-center w-full h-full bg-theme-on-primary">
        <MidSelector
          viewOnly={midsViewOnly}
          hasDirectAccessToSelectedHierarchy={hasDirectAccessToSelectedHierarchy}
          title={midSelectorTitle}
          onCancelChanges={toggleMidModal}
          onMidsSelected={onPartialMidsSelected}
          selectedHierarchyId={selectedHierarchyId}
          midList={availableMids}
          selectedTiers={tiers}
          initialSelectedMids={selectedMids}
          selectedMidRows={selectedMidRows}
          showApplyChanges
          targetUserEmail={someoneElseEmail}
          selectedPortfolio={selectedPortfolioData?.affiliation}
        />
      </div>
    </Modali.Modal>

    </>
);
};

export default HierarchySelector;
