import React, { useState, useRef, useEffect, useCallback } from 'react';
import useStateRef from 'react-usestateref';
import {AgGridReact} from "ag-grid-react";
import AllCommunityModules from "ag-grid-community";
import { ServerSideRowModelModule } from 'ag-grid-enterprise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { isMobileOnly } from "react-device-detect";
import useMerchantList from 'hooks/use-merchant-list';
import CheckboxHeaderComponent from 'components/GridOld/CheckboxHeaderComponent';
import MidSelectorMobileColumn from './MidSelectorMobileColumn';
import Pill from 'components/pill';
import Pagination from "components/GridOld/Pagination";
import PaginationPicker from "components/GridOld/PaginationPicker";
import TextRenderer from "components/Grid/columns/text-column";
import CheckBoxRenderer from "components/GridOld/checkboxRenderer";
import SortableHeaderComponent from 'components/GridOld/SortableHeaderComponent';
import CustomLoader from 'components/GridOld/customLoader';
import CustomNoRowsOverlay from 'components/GridOld/customNoRowsOverlay';
import './MidSelector.scss';
import HierarchyDescriptorLabel from 'components/DataAccessEditor/SearchByHierarchy/HierarchyDescriptorLabel';
import { usePortalAppContext } from 'context/portal-app-context';
import SelectedMids from './SelectedMids';
import Grid from 'components/Grid/grid';
import { CellShape } from 'enums';
import { filter } from 'interfaces';
import LocalizedText from 'components/Translations/LocalizedText';
import useLocale from 'hooks/use-locale';
import AccessibleIcon from 'components/Translations/AccessibleIcon';

const AgGridFilters = props => {
  const filterRef = useRef();
  const [searchedValue, setSearchedValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { translateToString } = useLocale();

  const handleChange = (e) => {
    setSearchedValue(e.target.value);
    if (e.target.value == "" || e.target.value.length > 1) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const val = e.target.value;
      const id = setTimeout(() => {
        props.onSearch(val);
      }, 3000)
      setSearchTimeout(id);
    }
  }

  const handleKeyDown = (e) => {

    var key = e.keyCode || e.charCode;
    if (!/[0-9A-Za-z !@#$%^()_+\-=\[\]{};':"\\|,.<>\/?]/.test(e.key) && key !== 8 && !(e.ctrlKey && (e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65))) {
      e.preventDefault();
      return
    }
    if(e.key.length !== 1 && key !== 13 && key !== 8){
      return
    }
    let value = e.target.value + (e.key.length  == 1 ? e.key : '');
    if(key == 8 && e.target.value) {
      value = e.target.value.slice(0, -1)
    }
    if ((value.length > 1 && e.keyCode == 13) || value.length === 0) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      if (value.length === 0) {
        setSearchedValue('');
      }
      props.onSearch(value);
    }
  };

  const showText = searchedValue.length < 2 && searchedValue.length !== 0;

  return (
    <div className="ag-grid__filter flex md:justify-between med:justify-end py-[8px] relative mb-[8px]">
      <div className="med:hidden ag-grid__filter--input flex items-center w-1/2 min-w-[420px]">
        <div className="w-full">
          <AccessibleIcon icon={faSearch} labeledBy="quickFilter" classes="absolute text-theme-medium pl-[12px] pt-[12px] text-[26px] pointer-events-none" />
          <input 
            id="quickFilter"
            value={searchedValue}
            autoComplete="off"
            className={`w-full text-sm  border border-solid border-gray-200 rounded-sm p-[8px] pl-[40px] font-normal 
              text-theme-dark bg-theme-on-primary`} 
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            ref={filterRef} type="text" placeholder={translateToString('midSelector.search.placeHolder')} 
          />
          {showText && <div className='w-1/2 min-w-[420px] text-xs whitespace-pre border border-solid border-gray-200 rounded-sm p-[8px] pl-[10px] font-normal 
              text-theme-medium bg-theme-on-primary z-10 absolute'>
            <LocalizedText localeKey="midSelector.search.minChars" templateVariables={{ minChars: 2 }} />
          </div>} 
        </div>
      </div>
      <div className="flex justify-end items-end">
        {!props.viewOnlyList && <Pill 
          themeColor="theme-success" 
          minWidth={75} 
          labelText={<LocalizedText localeKey="midSelector.midsTotal" templateVariables={{ totalMidsSelected: props.totalMidsSelected || 0 }} />} 
        />}
      </div>
    </div>
  );
};


const MidSelector = ({
    midList = [],
    hasDirectAccessToSelectedHierarchy,
    title,
    selectedHierarchyId,
    selectedTiers,
    viewOnly = false,
    onCancelChanges,
    onMidsSelected,
    initialSelectedMids,
    selectedMidRows = [],
    showApplyChanges = false,
    gridHeightClass = 'ag-grid-height',
    targetUserEmail,
    showSelectedMidsSection = true,
    selectedPortfolio,
    isSmallMerchantMidSelection,
  }) => {
  const merchantList = useMerchantList(selectedHierarchyId); // selectedTiers[2].selectedChild.hierarchyId);
  const { config, enqueueAlert } = usePortalAppContext();
  const [selectedMids, setSelectedMids, selectedMidsRef] = useStateRef(selectedMidRows);
  const [gridLoadingState, setGridLoadingState] = useState({});
  const { isLoading, totalRowCount } = gridLoadingState;
  const [rowData, setRowData] = useState([]);
  const [expandedRows, setExpandedRows, expandedRowsRef] = useStateRef({});
  const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState(false);
  const [, setOmniSearch, omniSearchRef] = useStateRef([]);
  const [refreshColumnDefsToggle, setRefreshColumnDefsToggle] = useState(false);
  const isClientSideGrid = ((viewOnly && selectedMidRows.length > 0) || isSmallMerchantMidSelection); // || !hasDirectAccessToSelectedHierarchy; // || midList?.length > 0;
  const selectedMidsPortfoliosSet = new Set(midList.map(h => h?.portfolioName));
  const isInitialRender = useRef(true);
  const gridApiRef = useRef(null);
  
  useEffect(() => {
    if (isClientSideGrid) {
      const setMidRows = (rows) => {
        const filteredMids = rows.filter(m => m?.value !== 'All');
        setRowData(filteredMids);
      }

      if (selectedMidRows?.length > 0 && !isSmallMerchantMidSelection) {
        setMidRows(selectedMidRows);
      } else {
        setMidRows(midList);
      }  
    }
  }, [selectedMidRows?.length, midList]);

  useEffect(() => {
    if (!showApplyChanges && !isInitialRender.current) {
      onMidsSelected(selectedMidsRef.current, totalRowCount || midList.length);
    }
    isInitialRender.current = false;

  }, [selectedMidsRef.current]);

  const onClearAll = () => {
    setSelectedMids([]);
  };

  const onApplyChanges = () => {
    const MIDUpdateMessageKey = initialSelectedMids.length === 0 ? 'midSelector.toasts.successfullySelected' : 'midSelector.toasts.successfullyUpdated'
    // if deslecting all mids, don't show the toast
    if (selectedMidsRef.current.length > 0) {
      enqueueAlert({ alertType: 'toast', messageType: 'success', message: <LocalizedText localeKey={MIDUpdateMessageKey} />});
    }
    onMidsSelected(selectedMidsRef.current, merchantList.totalRow);
  };

  const onExpandedClick = (rowIndex, gridApi) => {
    gridApiRef.current = gridApi;
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex]
    }));
    setRefreshColumnDefsToggle(prev => !prev);
    gridApi?.resetRowHeights();
  };

  const getRowHeight = (params) => {
    return expandedRowsRef.current[params.node.rowIndex] ? 80 : 40;
  }

  const onCancel = () => {
    onCancelChanges();
  };


  const setSelectedMidsRefresh = (s) => {
    setSelectedMids(s);
  };

  const disableApplyChanges = () => {
    if (initialSelectedMids.length === 0) return selectedMidsRef.current.length === 0;
    const allInitialMidsCheckeded = initialSelectedMids.every(initialMid => selectedMidsRef.current.find(m => m.hierarchyId === initialMid.hierarchyId));
    const allSelectedMidsWereInitial = selectedMidsRef.current.every(currentMid => initialSelectedMids.find(m => m.hierarchyId === currentMid.hierarchyId));
    return allInitialMidsCheckeded && allSelectedMidsWereInitial;
  };

  const shouldDisableAddOrApply = disableApplyChanges();
  const selectedMidsCssAdjust = showApplyChanges ? '' : 'ml-4 mt-[-20px]';
  const showSelectedMids = showSelectedMidsSection && !viewOnly && !isLoading && selectedMidsRef.current.length > 0;
  const midTotalForDisplay = viewOnly ? (totalRowCount || rowData.length) : selectedMids.length;

  const userUrl = config.API.userUrl2;
  const relativeUrl = `/hierarchy/hierarchies/mids/${selectedHierarchyId}`;

  let searchVal = merchantList.isNumber(omniSearchRef.current) ? `searchValue=${omniSearchRef.current}` : `searchValueForDBAName=${omniSearchRef.current}`;
  searchVal = targetUserEmail ? searchVal + `&targetUserEmail=${targetUserEmail}` : searchVal;
  const serverDatasource = {
    url: `${userUrl}${relativeUrl}`,
    reqType: 'get',
    sortMap: merchantList.sortMap,
    transformRow: merchantList.transformRow,
    queryParams: encodeURI(searchVal),
    reqBody: {},
    filtersUpdatedToggle,
  }

  const onSearch = (searchText) => {
    if (isClientSideGrid) {
      const mids = selectedMidRows?.length > 0 && !isSmallMerchantMidSelection ? selectedMidRows : midList;
      const filteredMids = mids.filter(h => (h.merchantId && h.merchantId.startsWith(searchText)) || (h.merchantName && searchString(h.merchantName, searchText)));
      setRowData(filteredMids);
    } else {
      setOmniSearch(searchText);
      setFiltersUpdatedToggle(!filtersUpdatedToggle);
    }
  }

  const searchString = (string, searchVal) => {
    return searchVal?.trim()?.toLowerCase()?.split(" ").every((s) => string?.trim()?.toLowerCase().indexOf(s) !== -1);
  };

  const onGridLoadingChange = (loadingState) => setGridLoadingState({...loadingState});

  return (
    <div className="mid-selector flex flex-col w-full h-full">
      {showApplyChanges &&
      <div className="w-full inline-flex items-center py-[20px] pl-[14px] pr-[2px] bg-theme-on-primary border border-solid border-gray-100" style={{height: 64}}>
        <div className="w-full flex space-x-12 items-center justify-start px-[16px]" style={{height: 24}}>
            <div className="flex w-full justify-start text-base font-medium leading-normal text-theme-dark flex gap-12 items-center">
              <div className="w-1/5 whitespace-nowrap">{title}</div>
              <div className="flex w-[22%] justify-start lrg:justify-start whitespace-nowrap">
                <div className="flex w-[63%] justify-start lrg:justify-start lrg:pr-[0px] items-center text-xs text-theme-medium w-full">
                  <LocalizedText localeKey="midSelector.subTitle" />:
                </div>
                <div className="flex w-[37%] lrg:w-[64%] lrg:pl-2 justify-start">
                  <HierarchyDescriptorLabel tiers={selectedTiers} selectedPortfolios={[selectedPortfolio]} portfolioClass="mx-2 my-auto text-theme-info font-medium text-lg"/>
                </div>
              </div>
            </div>
            {viewOnly &&
            <button className="close bg-theme-medium text-theme-on-primary float-right w-[20px] h-[20px] rounded-full text-xs flex justify-center items-center" onClick={onCancel}>
              <AccessibleIcon icon={faXmark} localeKey="common.icons.close" />
            </button>            
            }
        </div>
      </div>
      }
      <div className="flex flex-row justify-between">
        <div className="relative ag-grid__container min-w-[650px] med:min-w-full bg-theme-on-primary border-0 border-solid border-gray-200 rounded-sm w-full 
                        md:py-2 md:px-5 med:py-0 med:px-0.5">
          <AgGridFilters onSearch={onSearch} totalMidsSelected={midTotalForDisplay} viewOnlyList={viewOnly} />
          <Grid 
            rowModelType={isClientSideGrid ? 'clientSide' : 'serverSide'}
            serverDatasource={serverDatasource}
            rowData={rowData}
            selectedRows={selectedMids}
            rowIdFieldName={'hierarchyId'}
            onSelectedRowsChanged={setSelectedMids}
            loaderColumnDefs={merchantList.loaderColumnDefs}
            getRowHeight={getRowHeight}
            headerHeight={40}
            agGridClassName={`ag-grid w-full ag-grid__midSelector--${isSmallMerchantMidSelection ? 'small-merchant-height' : 'height'} overflow-auto`}
            paginationClass="ag-grid__footer flex justify-between items-center py-[8px] px-4 border-t border-solid border-gray-300"
            onGridLoadingChange={onGridLoadingChange}
            suppressHorizontalScroll={true}
            refreshColumnDefsToggle={refreshColumnDefsToggle}
          >
            <Grid.CheckboxColumn
              field='isSelected'
              width={20}
              maxWidth={45}
              hide={viewOnly}
              suppressMovable
              cellStyle={{ height: '40px' }}
            />
            <Grid.CustomColumn
              field='merchantNameCustom'
              headerNameKey='midSelector.columns.merchantName'
              resizable
              suppressMovable
              width="100%"
              cellStyle={{ padding: "0 20px" }}
              customComponent={MidSelectorMobileColumn}
              customComponentParams={{ onExpandedClick, expandedRows, viewOnly, className: 'w-full' }}
              hide={!(isSmallMerchantMidSelection && isMobileOnly)}
            />
            <Grid.TextColumn
              field='portfolioDisplayName'
              headerNameKey="midSelector.columns.portfolio"
              cellStyle={{ display: 'flex', fontSize: '14px', fontWeight: 500 }}
              maxWidth={175}
              minWidth={150}
              suppressMovable
              hide={!(selectedMidsPortfoliosSet.size > 1 && isSmallMerchantMidSelection) || isMobileOnly}
            />
            <Grid.TextColumn
              field='merchantId'
              headerNameKey="midSelector.columns.merchantId"
              cellStyle={{ display: 'flex', fontSize: '14px', fontWeight: 500 }}
              minWidth={80}
              suppressMovable
              hide={isMobileOnly}
            />
            <Grid.TextColumn
              field='merchantName'
              headerNameKey='midSelector.columns.merchantName'
              cellStyle={{ display: 'flex', fontSize: '10px'}}
              minWidth={100}
              suppressMovable
              hide={isMobileOnly}
            />
            <Grid.TextColumn
              field='address'
              headerNameKey='midSelector.columns.address'
              cellStyle={{ display: 'flex', fontSize: '9px'}}
              minWidth={100}
              suppressMovable
              hide={isMobileOnly}
            />
            <Grid.Pagination />
          </Grid>
        </div>
        {!viewOnly && !isSmallMerchantMidSelection && 
          <div className={`${selectedMidsCssAdjust} ${showSelectedMids ? 'visible' : 'invisible'} lrg:hidden`}>
            { Object.keys(gridLoadingState)?.length > 0 && 
              <SelectedMids
                selectedMids={selectedMidsRef.current}
                setSelectedMids={setSelectedMidsRefresh}
                onClearAll={onClearAll}
              />
            }
          </div>
        }
      </div>

      {!viewOnly && showApplyChanges &&
      <div className="inline-flex space-x-96 items-center justify-start pt-[12px] pb-[10px] bg-theme-light-background border mt-[8px]" style={{border: '1px solid #D7DCE1', height: 64}}>
        <div className="flex items-start justify-start pl-8 pr-4">
            <div className="inline-flex flex-col items-center justify-start px-[16px] py-[8px] rounded-sm">
                <button type="button" className="text-sm font-bold leading-snug text-center text-red-700 border-0" onClick={onCancel}>
                  <LocalizedText localeKey="midSelector.buttons.cancel" />
                </button>
            </div>
        </div>
        <div className="flex-grow grid grid-cols-1 place-items-end space-x-2.5 items-start px-[16px]">
            <div>
              <button type="button"
              disabled={shouldDisableAddOrApply || (initialSelectedMids.length === selectedMidsRef.current.length && selectedMidsRef?.current?.map(sr => sr?.hierarchyId)?.filter(item => !initialSelectedMids?.includes(item))?.length === 0) }
              className="flex w-full flex-col mr-[12px] items-center justify-end px-[16px] py-[8px] border rounded-sm  text-theme-on-primary border-gray-300 bg-theme-primary text-sm font-bold leading-snug text-center text-theme-on-primary disabled:text-theme-medium disabled:bg-transparent disabled:border-0"
              onClick={onApplyChanges}
              data-test="midselector-apply-btn"
              >
                {(initialSelectedMids.length) > 0 && <LocalizedText  localeKey="midSelector.buttons.applyChanges" />}
                {(initialSelectedMids.length === 0) && <LocalizedText localeKey="midSelector.title.primary" />}
              </button>
            </div>
        </div>
      </div>
      }
    </div>
    
  );
};

export default MidSelector;