import React, { useCallback, useEffect, useRef, useState, useLayoutEffect} from 'react';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import useStateRef from 'react-usestateref';
import LocalizedText from 'components/Translations/LocalizedText';
import useLocale from 'hooks/use-locale';

const HierarchyTierSelector = ({ 
  dropdownPortal,
  allowAll = true,
  isLoading,
  isDisabled,
  isEditing,
  isCreateMode,
  requiresServerSearch,
  searchList,
  isFocused,
  tier,
  tierName,
  tierIndex,
  onHierarchySelected,
  selectClassName,
  lazyLoadListItems, 
  editMode,
  lockRowFromEditing
}) => {
  const selectRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen, menuIsOpenRef] = useStateRef(false);
  const [searchText, setSearchText] = useState(null);
  const [lastSearchText, setLastSearchText] = useState(null);
  const [lazyLoadRequiresServerSearch, setLazyLoadRequiresServerSearch] = useState(!isCreateMode);
  const [groupedOptions, setGroupedOptions] = useState([
    {
      label: <>{tierName}:</>,
      options: [],  
    }
  ]);
  const [hasSinglePreselectedOption, setHasSinglePreselectedOption] = useState(false);
  const { translateToString } = useLocale();
  const allLabel = translateToString('hierarchySelector.selectDataAccess.hierarchy.tiers.all');
  const tierParentKey = `${tier.tierName}-${tier.parentHierarchyId || ''}`;

  useLayoutEffect(() => {
    if (menuIsOpenRef.current) {
      const element = document.getElementsByClassName('hierarchy-tier-selector__menu')[0];
      if (element) {
        setTimeout(() =>element.scrollIntoView(), 100);
      }
    }
  }, [menuIsOpenRef.current]);

  useEffect(() => {
    async function setSearch() {
      if (!isDisabled && isFocused && isEditing) {
        if (searchText === null) await setCurrentSearch('');
        selectRef.current?.focus();
        customStyles.dropdownIndicator.display = 'flex';
      } else if (!isFocused) {
        customStyles.dropdownIndicator.display = 'none';
      }
    };
    setSearch();

  }, [selectRef, isFocused, searchText, isDisabled]);

  const onFocus = async () => {
    await setCurrentSearch(searchText || '');
  }

  const toggleMenuState = () => {
    setMenuIsOpen(!menuIsOpenRef.current);
  }

  const onChange = (args) => {
    setSearchText(null);
    if (onHierarchySelected) onHierarchySelected(args, tierIndex, tier);   
  };

  const portalTarget = document.getElementById(dropdownPortal);

  const setCurrentSearch = (searchInput) => {
    setSearchText(searchInput);
    if (searchInput.length === 1 && searchInput !== lastSearchText) {
      setLastSearchText(searchInput);
    }
  };

  const loadOptions = async(searchInput) => {
    if (tier?.children?.length === 1 && !tier?.selectedChild) {
      onHierarchySelected(tier?.children[0], tierIndex, tier);
    }  
    
    // if (tier?.children?.length === 1 && editMode && !tier?.selectedChild ){
    //  onHierarchySelected(tier?.children[0], tierIndex, tier);
    // }

    const defaultItem = {
      hierarchyId: "",
      label: <LocalizedText localeKey="hierarchySelector.selectDataAccess.hierarchy.tiers.placeholder" />,
      value: "",
      isDisabled: true,
    };
    const newGroup = [{
      label: <>{tierName}:</>,
      options: [],
    }];
    let children = tier.children;
    let isUsingLazyLoad = false;
    if ((!isCreateMode && lazyLoadListItems && (tier?.children?.length !== 1 || editMode)) || (requiresServerSearch && searchInput.length >= 1)) {
      isUsingLazyLoad = true;     
      children = await lazyLoadListItems(tierIndex, searchInput);
      
      setLazyLoadRequiresServerSearch(false);
    } 
    let items = searchInput.length > 0 ? children.filter(x => x.label.indexOf(searchInput) === 0) : children;
    items = (items || []).slice(0, 1000);
    setHasSinglePreselectedOption(children.length === 1 && !isUsingLazyLoad);
    if (children.length > 1) items.unshift(defaultItem);
    newGroup[0].options = items;
    setSearchText(searchInput);
    setGroupedOptions(newGroup);
    setLastSearchText(searchText);
    return newGroup;
  };

  const loadOptionsCallback = useCallback(loadOptions, [allowAll, searchText, lastSearchText, tierParentKey]);

  const customStyles = {
    control: (provided) => ({
      border: 'none',
      display: 'flex',
      padding: 1,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      display: isFocused ? 'none' : 'flex',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      // display: isFocused ? 'none' : 'flex',
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
      marginTop: '2px',
    }),
    menuPortal: () => ({
      // position: 'absolute',
      // top: !isCreateMode ? 205 : 195,
      // left: !isCreateMode ? 47 : 58,
      width: 200,
      maxWidth: 200,
    }),
    groupHeading: () => ({
      color: '#9296A5',
      'backgroundColor': '#F8F9FC',
      'marginTop': 0,
      'borderBottom': '1px solid #D7DCE1',
       padding: '5px 0px 5px 15px',
    }),
    group: () => ({
      padding: 0,
      margin: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      // marginBottom: '-40px',
      maxHeight: '214px',
      overflowX: 'hidden',
      overflowY: 'auto',
    }),
    valueContainer: (provided) => ({
      ...provided,
      // padding: '0px 2px',
      padding: 1,
      // display: 'flex',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#242729',
      'fontWeight': '500',
      'lineHeight': '30px',
      'fontSize': '14px',
      padding: 0,
      minWidth: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: groupedOptions[0]?.options?.length === 1 ? '#BCBFC8' : '#005C9E',
      'fontWeight': groupedOptions[0]?.options?.length === 1 && !editMode ? '500' : '700',
      'lineHeight': '30px',
      'fontSize': '14px',
      padding: 0,
      minWidth: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : '#3A3D46',
      'fontWeight': '500',
      padding: '6px 0px 0px 15px',
      'lineHeight': '18px',
      'fontSize': '12px',
      'borderBottom': '1px solid #D7DCE1',

    })
  };

  const PlaceholderComponent = (   
    <div>
      {!searchText?.length && groupedOptions[0]?.options?.length === 1 && <div className="text-sm font-medium placeholder-gray-200 text-theme-disabled w-[72px] truncate">
        {tier?.selectedChild?.label}
      </div>} 
      {groupedOptions[0]?.options?.length !== 1 &&  menuIsOpenRef.current && <div className="placeholder-gray-200 text-xs">
        {tierName}
        <FontAwesomeIcon className='ml-1' icon={faCaretDown} />
      </div>}
      {groupedOptions[0]?.options?.length !== 1 && !menuIsOpenRef.current && <div className="placeholder-gray-200 text-xs text-theme-disabled">
        {tierName}
        <FontAwesomeIcon className='ml-1' icon={faCaretDown} />
      </div>}
    </div>
  );

  const getCustomOptionLabel = (option) => {
    return option.label === 'All' ? allLabel : option.label
  }

  if (isEditing) {
    return (
      <div className={`h-[40px] ${selectClassName}`} data-test="dataaccess-tier-input">
        <AsyncSelect
          ref={selectRef}
          key={tierParentKey}
          autoFocus={isFocused}
          defaultMenuIsOpen={groupedOptions[0]?.options?.length === 1 ? false : isFocused}
          isLoading={isLoading}
          onFocus={onFocus}
          onInputChange={setCurrentSearch}
          className={`h-full text-sm font-medium text-gray-600 mt-[4px] px-0 ${selectClassName}`}
          openMenuOnFocus
          placeholder={PlaceholderComponent}
          filterOption={item => allowAll ? true : item.label !== allLabel}
          styles={customStyles}
          defaultValue={tier.selectedChild}
          loadOptions={loadOptionsCallback}
          defaultOptions
          onChange={onChange}
          onMenuOpen={toggleMenuState}
          onMenuClose={toggleMenuState}
          pageSize={8}
          isDisabled={tier?.children?.length === 1 && groupedOptions[0]?.options?.length === 1}
          classNamePrefix="hierarchy-tier-selector"
          noOptionsMessage={() => <LocalizedText localeKey="hierarchySelector.selectDataAccess.hierarchy.tiers.noOptions" /> }
          getOptionLabel={getCustomOptionLabel}
          loadingMessage={()=><><LocalizedText localeKey="common.loading"/>...</>}
        />
      </div>
    );
  } else {
    return (
      <div className="h-[40px] flex pr-6">
        <div className={"h-full text-base font-bold flex items-center px-[8px] " + (lockRowFromEditing ? 'text-theme-disabled' : 'text-theme-dark')}>
          {tier && tier.selectedChild && tier.selectedChild.label !== 'All' && tier.selectedChild.label}
          {tier && tier.selectedChild && tier.selectedChild.label === 'All' && <div className="invisible">000000</div>}
        </div>
      </div>
    );
  }
};

export default HierarchyTierSelector;

