import React, { useState } from 'react';
import PanelDropdown from 'components/PanelDropdown';
import useDataHierarchy from 'hooks/use-hierarchy';
import LocalizedText from 'components/Translations/LocalizedText';

const PortfolioSelector = ({ portfolios = [], onPortfolioChanged, selectedPortfolio, title, icon, containerClass }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [dropdownEngaged, setDropdownEngaged] = useState(false);
    const { portfolioNameMap: portfolioNames } = useDataHierarchy();
    const portfolioNameMap = {
      ...portfolioNames,
      'selectPortfolio': <span>-<LocalizedText localeKey="hierarchySelector.selectDataAccess.portfolioSelector.selectPortfolio" />-</span>
    }

    const sortedPortfolios = [...portfolios].sort((a,b) => a.affiliation === b.affiliation ? 0 : a.affiliation > b.affiliation ? 1 : -1);
    const portfolioList = selectedPortfolio?.name ? [...sortedPortfolios] : [{affiliation: 'selectPortfolio'}, ...sortedPortfolios];
    const onDropdownEngaged = (buttonIdx) => {
      if (!dropdownEngaged && buttonIdx !==0 ) setDropdownEngaged(true);
    }

    const onPanelOpenChange = (opened) => {
      if (!opened) setDropdownEngaged(false);
      setIsOpen(opened);
    }
    
    const onPortfolioSelected = (p) => {
      onPortfolioChanged(p.affiliation);
      setIsOpen(false);
    }

  return (
    <PanelDropdown
      title={title}
      containerClass={`bg-theme-on-primary h-[34px] ${containerClass}`}
      buttonClass = 'h-[34px] w-10 text-theme-primary'
      utilityClass='text-theme-primary p-2'
      onOpenChange={onPanelOpenChange}
      buttonIcon={icon}
      panelIsOpen={isOpen}
    >
      <div className="text-sm ">
        {portfolioList.map((p, idx) => {
          const portfolioBtnStyles = (!selectedPortfolio?.name && idx === 0) ? '' : 'bg-theme-on-primary hover:bg-theme-info hover:bg-opacity-20 disabled:text-theme-disabled disabled:bg-theme-light-background';
          return (
            <button
              className={`w-full h-[34px] flex items-center font-medium ${(!selectedPortfolio?.name && idx === 0) ? 'justify-center' : 'px-4'} border-0 bg-theme-on-primary ${portfolioBtnStyles}`}
              style={{ borderTop: '1px solid #d7dce1', backgroundColor: (!selectedPortfolio?.name && !dropdownEngaged && idx === 0) ? 'rgba(0, 92, 158, 0.2)' : undefined }}
              onClick={() => onPortfolioSelected(p)}
              key={p.affiliation}
              disabled={(!selectedPortfolio?.name && idx === 0) || p.fullPortfolioHierarchySelected}
              onMouseEnter={() => onDropdownEngaged(idx)}
            >
              {portfolioNameMap[p.affiliation]}
            </button>
          );
        })}
      </div>
    </PanelDropdown>
  );
};

export default PortfolioSelector;
