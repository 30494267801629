import React from 'react'
import { ColumnFunctionCallbackParams } from 'ag-grid-community';

interface ICustomColumnItems {
    data: any,
    customString: string,
    customComponent: React.ComponentType<any>,
    customComponentParams: { [key: string]: any },
    className: string
}

export interface ICustomColumn extends ICustomColumnItems, ColumnFunctionCallbackParams {}; 

const TextColumn: React.FC<ICustomColumn> = ({ customComponent: CustomComponent, customComponentParams = {}, ...rest }) => {

    const { className = '', ...restCustomComponentParams } = customComponentParams;

    return (
        <div className={className}>
            <CustomComponent {...restCustomComponentParams} { ...rest} />
        </div>
    )
}

export default TextColumn;
