/*  
This file serves as a structural template for the translation keys and a fall back if translations are not available via the api. It provides intellisense support
for developers to boost productivity and ensure correct usage of translation keys. This file should be maintained with current keys and English values.
*/

const masterTranslations = {
  globalTranslate: {
    alerts: {
      default: {
        continueCaption: "Ok",
      },
      generalError: {
        title: "Error",
        message: "An error occurred while processing your request.  Please try again later.",
      },
      generalLimit: {
        message:
          "We're sorry, but due to system limitations we cannot navigate you to the page you have requested. Please use the filtering or search functionality to narrow down the number of results.",
      },
      dataAccessGroup: {
        users: {
          assigned: {
            title: "Cannot Delete Group",
            message:
              "You cannot delete Custom Data Access groups that currently have users assigned to them.  You must remove all users from this group before deletion can occur.",
          },
        },
      },
      user: {
        alreadyAssigned: {
          full: {
            access: {
              title: "Cannot Assign User to Group",
              message:
                "This user is already assigned full access to the MIDs available and therefore cannot be assigned redundant access.",
            },
          },
        },
        hierarchy: {
          accessDenied: {
            message: "Calling user does not have access to the requested hierarchy.",
          },
        },
        hierarchyAssignment: {
          alreadyAssigned: {
            title: "User Already Added",
            message:
              "The user you selected has already been added to this data access group.  If you intended to add a different user, please try again.",
          },
        },
      },
      cannot: {
        delete: {
          data: {
            access: {
              group: {
                title: "Cannot Remove User",
                message:
                  "This custom group is the only data access assigned to this user. In order to remove them from this group, they must be assigned separate data access using the User Management tool. If you do not have this permission, please contact your Account Administrator.",
              },
            },
          },
        },
        create: {
          data: {
            access: {
              group: {
                title: "Group Creation Failed",
                message:
                  "We’re sorry, but we experienced an error when trying to create this Data Access group. Please check the information you have entered and try again.",
              },
            },
          },
        },
      },
    },
    filters: {
      filters: "Filters",
      clearAllFiters: "Clear All Filters",
      all: "ALL",
      selected: "selected",
      removeFilter: "remove filter",
      omniSearch: {
        minChars: "Please enter at least %minChars% characters to search by.",
        maxPhrases: "You can only enter %allowedSpaces% distinct phrases at a time to search by.",
      },
      dbaSearch: {
        maxSelections: "You have reached the maximum number of selections allowed",
        minChars: "Please enter at least %minChars% character to retrieve list of DBAs",
        noMatch: "No matching DBAs found",
      },
      midSearch: {
        placeholder: "Please enter at least %minNumbers% numbers to retrieve list of MIDs",
        searching: "Searching, please wait...",
        noMatch: "No matching MIDs found",
        onlyNumbers: "Only numbers are allowed.",
      },
    },
    common: {
      icons: {
        dropdownArrow: "dropdown arrow",
        close: "close",
      },
      noSpecialChars: "",
      loading: "Loading",
    },
    grid: {
      pagination: {
        showing: "Showing",
        rowCountDesc: "of %rowCount% Records",
        pageCountDesc: "of %totalPages%",
        page: "Page",
      },
      noRecsFound: {
        title: "No records found.",
        description: "Please expand your search, check your spelling, or remove filters.",
      },
    },
    midSelector: {
      columns: {
        portfolio: "Portfolio",
        merchantId: "Merchant ID",
        merchantName: "Merchant Name",
        address: "Address",
        hierarchy: "Hierarchy",
      },
      title: {
        primary: "Select MIDs",
        edit: "Edit MID List",
        view: "MID List",
      },
      subTitle: "Available MIDS in Hierarchy",
      midsTotal: "%totalMidsSelected% MIDs",
      search: {
        placeHolder: "Search by full/partial MID or merchant name",
        minChars: "Please enter at least %minChars% characters to retrieve results",
      },
      selectedColumn: {
        title: "Selected MIDs",
      },
      buttons: {
        applyChanges: "Apply Changes",
        cancel: "Cancel",
        clearAll: "Clear All",
      },
      toasts: {
        successfullySelected: "MIDs successfully selected.",
        successfullyUpdated: "MIDs successfully updated.",
      },
    },
    hierarchySelector: {
      panelDropdown: {
        selectActions: "Select Actions",
        chooseMIDs: "Choose MIDs from List",
        addAllMIDs: "Add All MIDs",
      },
      selectDataAccess: {
        title: "Select Data Access by:",
        alerts: {
          deleteWarning: {
            title: "Warning",
            message:
              "This will remove all of the data access included in this hierarchy selection.  Do you wish to continue? ",
            continueCaption: "Yes, Delete",
            cancelCaption: "No, Cancel",
          },
          deleteSelectedMID: {
            message: "This will remove %deletedMid% from the list of selected MIDs. Do you wish to continue?",
          },
          multiPortfolio: {
            message: "You are adding MIDs from more than one portfolio.  Do you wish to continue?",
          },
        },
        hierarchy: {
          name: "Hierarchy",
          title: "Select By Hierarchy",
          tiers: {
            tierNames: {
              bank: "BANK",
              group: "GROUP",
              assoc: "ASSOC",
              corp: "CORP",
              reg: "REG",
              prin: "PRIN",
              chain: "CHAIN",
              taxid: "TAX ID",
              domain: "DOM"
            },
            placeholder: "-Please Select-",
            all: "All",
            noOptions: "No options",
          },
          fullPortfolio: "FULL PORTFOLIO",
          allMIDs: "ALL MIDS",
        },
        mid: {
          name: "MID",
          title: "Search MIDs",
        },
        dataAccessGroup: {
          name: "Data Access Group",
          title: "Select Data Access Group",
          selectedTitle: "DATA ACCESS GROUPS",
          search: {
            placeholder: "Select group or type name to search for group",
            limit:
              "Your search has returned too many results to be displayed. Please refine your search by adding more info into the search box.",
            minChar: "Please enter at least %minChar% characters into the search box to return a list of results.",
            noMatch: "No matching Groups found",
          },
        },
        portfolioSelector: {
          selectPortfolio: "Select Portfolio",
        },
      },
      notifications: {
        noDataAccess: {
          title: "NO DATA ACCESS SELECTED",
          message: "You must add data access to save the changes you made.",
        },
      },
      alerts: {
        addingFullPortfolio: {
          continueCaption: "Yes, add full portfolio",
          message:
            "Because you are adding a full portfolio, the other data access selections will be deleted.  Do you wish to continue?",
        },
      },
      outsideMIDs: "There are other MIDs outside of your data access that are not displayed here.",
    },
    roleChange: {
      notifications: {
        titles: {
          dataAccessWarning: "DATA ACCESS WARNING:",
          dataAccessConfigError: "DATA ACCESS CONFIGURATION ERROR",
        },
        messages: {
          reconfigureDataAccess:
            "Because you are changing the role from %originalRoleName% to %newRoleName%, the Data Access for this user may need to be reconfigured.",
          reviewTab: "Please review the Data Access tab before you save changes.",
          removeFullPortfolio:
            "In order to save this change, you need to remove full portfolio from the Data Access screen.",
        },
      },
    },
    logoutTimer: {
      title: "Session Timeout",
      message1: "Your session will automatically expire in <strong>%responseTimer%</strong> seconds.",
      message2: "Click Renew to stay logged in.",
      buttons: {
        logout: "Logout",
        renew: "Renew",
      },
    },
    dataAccessEditor: {
      warning: {
        continueCaption: "Yes, continue",
        message: "Changing the search by will clear any selections made for this row.  Continue?",
      },
    },
    exportFile: {
      title1: 'You can minimize this screen and continue to work.',
      title2: 'You will be notified when the download is complete.',
      message: 'A CSV will be generated containing all of the transactions available. If any filters have been selected, the exported data will also be filtered.',
      downloadButtonLabel: 'Download CSV',
      cancelButtonLabel: 'Cancel',
      downloadingLabel: 'Downloading...',
      exportLabel: 'Export'
    }
  },
};

export default masterTranslations;
