import React from "react";
import useLocale from "hooks/use-locale";
import AccessibleIcon from "components/Translations/AccessibleIcon";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const MidSelectorMobileColumn = ({ onExpandedClick, expandedRows, viewOnly, node, data, api: gridApi }: {[key: string]: any }) => {
    const expanded = !!expandedRows[node?.rowIndex];
    const arrowIcon = expanded ? faChevronUp : faChevronDown;
    const { translateToString } = useLocale();

    return (
      <div className="flex flex-col w-full">     
        <button
            id="dropdown-button"
            type="button"
            onClick={() => {
                onExpandedClick(node?.rowIndex, gridApi);
            }}
            aria-expanded={expanded} 
            className='flex justify-between items-center leading-tight h-10'
        >
            <div className='flex flex-col items-start'>
                <span style={{fontWeight: '600'}}>{data.merchantName}</span>
                <span>{data.merchantId}</span>
            </div>
            <div 

                className={`flex items-center justify-center`}

            >
                <AccessibleIcon localeKey={'common.icons.dropdownArrow'} icon={arrowIcon} style={{ opacity: 0.5}} />
            </div>
        </button>
        {expanded && (
            
            <div 
                className={`mobile-rows relative flex flex-col justify-center leading-tight h-10 ml-[-4.1rem] ${viewOnly ? 'pl-16' : 'pl-[4.1rem]'} text-[0.688rem]`}
                style={{ background: 'linear-gradient(to bottom, rgba(0, 0, 0, .10) 0%, rgba(0, 0, 0, .05) 10%, rgba(0, 0, 0, 0) 30%)'}}
            >
                {!data.portfolioDisplayName && <span>{data.merchantName}</span>}
                {<span className="truncate">{data.portfolioDisplayName ? <span className="!font-bold">{translateToString("midSelector.columns.address")}: </span> : ''}{data.address}</span>}
                {data.portfolioDisplayName && <span><span className="!font-bold">{translateToString("midSelector.columns.portfolio")}: </span>{data.portfolioDisplayName}</span>}
            </div>
            
        )}
      </div>
    );
};

export default MidSelectorMobileColumn;

