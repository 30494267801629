import React from 'react';
import useLocale from 'hooks/use-locale';
import useDataHierarchy from 'hooks/use-hierarchy';

const HierarchyDescriptorLabel = ({tiers, selectedPortfolios, portfolioClass}) => {
  const { portfolioNameMap } = useDataHierarchy();
  const portfolioNames = selectedPortfolios.map(p => portfolioNameMap[p]).join(' / ');

  const renderTierLabels = () => {
    const { translateToString } = useLocale();
    if (!tiers || tiers.length === 0) return (<></>);
    
    return (
      <>
        {tiers.map((tier, index) => {
          return (
            <div key={index} className="h-10 flex flex-row">
              <div className="h-full text-lg font-medium text-gray-600 mt-1.5 px-1">{tier && tier.selectedChild && tier.selectedChild.label !== 'All' && tier.selectedChild.label}</div>
            </div>
          );
        })
        }
      </>
    );
  };

  return (
    <>
        <div className={portfolioClass || "mx-2 my-auto text-theme-info font-medium text-sm"}>{portfolioNames}</div>
        <div className="flex xl:w-72 w-min justify-start">{renderTierLabels()}</div>
    </>
  );
};

export default HierarchyDescriptorLabel;

